import * as React from 'react';
import { Link, graphql } from 'gatsby';

import SeoHeader from '../components/Shared/SeoHeader';
import { PublicLayout } from '../components/Shared/PublicLayout';

export default function PublicPageTemplate({ data }: any) {
  const page = data.markdownRemark;

  const { previous, next } = data;

  return (
    <PublicLayout>
      <React.Fragment>
        <SeoHeader title={page.frontmatter.title} />
        <section className="bg-gray-50">
          <section className="transform skew-y-3">
            <section className="transform -skew-y-3 md:w-2/3 mx-auto px-4 py-10 sm:px-6 lg:px-16">
              <section className="mb-5">
                <h1 className="font-bold text-4xl text-primary-1 mb-5">
                  {page.frontmatter.title}
                </h1>
                <p className="text-sm font-bold">
                  Last Updated: {page.frontmatter.date}
                </p>
              </section>

              <section
                dangerouslySetInnerHTML={{ __html: page.html }}
              ></section>
            </section>
          </section>
        </section>

        <section className="text-primary-1 font-bold mt-5">
          <nav className="md:w-2/3 mx-auto py-2">
            <ul
              className="text-xs"
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </section>
      </React.Fragment>
    </PublicLayout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
